<template>
  <div class="outer-style">
    <div>
        <form id="myform">
        Last Name: <input type="text" name="first_name"> 
        <br> Last Name:<input type="text" name="last_name">
    </form>
    <el-button @click="getParams">获取序列化参数</el-button>
    {{text}}
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
export default {
  data() {
    return {
        text:null,
    };
  },
  mounted(){
  },
  methods:{
    getParams(){
      this.text = $('#myform').serialize();
      $.ajax({
		 		 type: "GET",    
		         url: process.env.VUE_APP_API_USERINFO + "/user/myInfo",    
		         data: {
		        	 str1:$('#myform').serialize(),
		         },
		         dataType:"json",//返会值的类型
		         cache : false,
             success: (data)=>{
                         console.log(data);
            }
				 });
    }
  }
};
</script>
<style lang="scss" scoped>

.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
</style>